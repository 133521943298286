import React from "react";
// Customizable Area Start
import HeaderNavbar  from '../../../components/src/Navbar'
import DashboradProject from "../../../components/src/DashboardProject";
import { AccordionDetails, Box, Button, Modal,createTheme,Container, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, Table ,Dialog,DialogContent, DialogTitle, TextField, Theme, InputAdornment, IconButton, DialogActions, TextareaAutosize, Grid, FormHelperText, MenuItem, Select } from "@material-ui/core";
import { editImage, pdfType, imageType, userImg } from "./assets";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import * as Yup from "yup";

const theme = createTheme({
  typography: {
    fontFamily: "Silka",
  },
});
const StyledContainer = styled(Box)({
  "& .parent": {
    disply: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    padding:"80px",
    [theme.breakpoints.down('sm')] :{
      width: "100%",
      padding: "24px"
    }
  },
  "& .headding": {
    marginBottom: 95,
    fontFamily: "silika-Medium",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    fontWeight: 500,
    fontSize: "32px",
    [theme.breakpoints.down('sm')] :{
      marginBottom : 24,
      fontSize: "20px",
    }
  },
  '& .pera':{
    fontFamily: "silika-Medium",
    fontWeight: 500,
    fontSize: "32px",
    textAlign:"center"
  },
  '& Button':{
    fontFamily: "silika-Medium",
    fontSize: "16px",
    width:"200px",
    height:"32px",
    backgroundColor:"#0D0C22",
    borderRadius:"2px",
    padding:"16px",
    textTransform: 'capitalize',
    color: "#FFFFFF",
   '&:hover': {
        backgroundColor: "#0D0C22",
        boxShadow: 'none',
    },  
  }
});
const StyledShareChatDial = styled(Dialog)({
  "& .MuiPaper-root":{
    minWidth: "1195px",
  },
  "& .btn1":{
    width: "87px",
    height: "48px",
    gap: "8px",
    borderRadius: "2px 0px 0px 0px",
    border: "1px solid var(--Dark-Blue-Prim, #0D0C22)",
    fontFamily: "Silka",
    fontSize: "16px",
    fontWeight: "500",
    textTransform:"capitalize"
  },
  "& .btn2":{
    width: "87px",
    height: "48px",
    gap: "8px",
    borderRadius: "2px 0px 0px 0px",
    fontFamily: "Silka",
    fontSize: "16px",
    fontWeight: "500",
    textTransform:"capitalize",
    background: "var(--Inactive-Button, #C4C4C4)",
    color:"white"
  },
  "& .cancelBtn": {
    textTransform: "unset",
    color: "#0D0C22",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    backgroundColor: "#FFFFFF",
    padding: "8px 10px",
    border: "1px solid #010101",
    cursor: "pointer",
    borderRadius: "1px"
  },
  "& .yesBtn": {
    textTransform: "unset",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    backgroundColor: "#010101",
    padding: "8px 10px",
    border: "1px solid #010101",
    cursor: "pointer",
    borderRadius: "1px",
    "&.disabledButton": {
      backgroundColor: "#c4c4c4",
      cursor: "not-allowed",
      border: "none",
      padding: "10px 10px",
    },
  },
})
const StyledDialogTitle = styled(DialogTitle)(({theme}:{theme: Theme}) => ({
  padding: "0px",
  "& .headding":{
    height: "60px",
    borderRadius: "4px 4px 0px 0px",
    background: "#0D0C22",
    color: "#FFFFFF",
    fontFamily: "Silka",
    alignContent:"center",
    fontSize: "16px",
    paddingLeft:"40px",
    fontWeight: "500",
  },
  '& .flexWrap': {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    padding:"0px 38px",
    curser:"pointer"
  },
}));
const StyledDialogContent = styled(DialogContent)(({theme}:{theme: Theme}) =>({
  display: "flex",
  flexFlow: "column",
  padding: "40px 41px",
  [theme.breakpoints.down('sm')]: {
      width: "calc(100% - 48px)",
      oveflow: "hidden",
  },
  "& .textMuted": {
    fontFamily: "Silka",
    fontSize: "16px",
    textAlign: 'right',
    fontWeight: "500",
    marginBottom: "8px",
    color: "#9B9B9B",
  },
  "& .textStyle": {
    fontFamily: "Silka",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "8px",
    color:"black",
    "& span":{
      color:"#FF5959"
    }
  },
  "& .selectStyle": {
    width: "100%",
    height: "48px",
    "&.Mui-error": {
      borderColor: "#FF5959"
    },
    "& .MuiSelect-select": {
      padding: "12px"
    }
  },
  "& .dateStyle": {
    width: "358px",
    marginRight: "20px",
    "& .MuiInputBase-root": {
      height:"48px",
      borderLeft: "1px solid #DFDFDF",

    },
    "& .MuiIconButton-edgeStart": {
      color: "black"
    },
    "& .react-datepicker-wrapper": {
      borderLeft: "1px solid #DFDFDF",
      borderTop: "1px solid #DFDFDF",
      borderBottom: "1px solid #DFDFDF",
      borderRadius: "2px",
      width: "100%",
      "&. error": {
        borderColor: "#FF5959",
      }
    },
    "& .datepickerStyle": {
      width: "100%",
      "&.error": {
        "& input": {
          // borderColor: "#FF5959",
          borderRadius:"2px"
        }
      }
    },
    "& input": {
      width: "100%",
      height: "48px",
      padding: "10px 8px",
      borderRight: "1px solid #DFDFDF",
      "&:focus": {
        outline: "none",
        borderColor: "#0D0C22"
      }
    }
  },
  "& .MuiFormControl-root":{
    width:"100%"
  },
  "& .inputStyle": {
    "& input":{
      width:"1114px",
      height: "48px",
      padding: "10px 8px 10px 8px",
      border: "1px solid var(--Light-Grey, #DFDFDF)"
    }
  },
  "& .CustominputStyle": {
    width: "100%",
    "& input": {
      height: "48px",
      padding: "10px 8px",
      border: "1px solid #DFDFDF",
      width: "100%",
      "&:focus": {
        outline: "none",
        borderColor: "#0D0C22"
      }
    }
  },
  "& .inputStyle1": {
    "& input":{
      height: "48px",
      padding: "10px 8px 10px 8px",
      border: "1px solid var(--Light-Grey, #DFDFDF)"
    }
  },
  "& .datepickerStyle":{
    "& input":{
      height: "48px",
      border: "none",
      outline: 'none',
      width:"100%"
    },
  },
  "& .textAreaStyle":{
    height: "139px !important",
    width: "100%",
    border: "1px solid #DFDFDF",
    padding: "10px 8px",
    fontSize: "16px",
    fontFamily: "Silka",
    resize: "none",
    "&:focus": {
      outline: "none",
      borderColor: "#0D0C22"
    }
  },

  "& .errorStyle": {
    height: "139px !important",
    width: "100%",
    border: "1px solid #FF5959",
    padding: "10px 8px",
    fontSize: "16px",
    fontFamily: "Silka",
    resize: "none"
  },

  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    color: "#FF5959",
    marginTop: "4px",
    fontSize: "12px"
  }

}))

const StyledShareApproveDial = styled(Dialog)({
  "& .MuiPaper-root": {
    minWidth: "600px",
    minHeight: "215px"
  },
  "& .cancelBtn": {
    textTransform: "unset",
    color: "#0D0C22",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    backgroundColor: "#FFFFFF",
    padding: "8px 10px",
    border: "1px solid #010101",
    cursor: "pointer",
    borderRadius: "1px"
  },
  "& .yesBtn": {
    textTransform: "unset",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "14px",
    minWidth: "102px",
    fontFamily: "Silika-Medium",
    backgroundColor: "#010101",
    padding: "8px 10px",
    border: "1px solid #010101",
    cursor: "pointer",
    borderRadius: "1px",
    "&.disabledButton": {
      backgroundColor: "#c4c4c4",
      cursor: "not-allowed",
      border: "none",
      padding: "10px 10px",

    },
  },
})
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ShareIcon from "@mui/icons-material/Share";
import DatePicker from "react-datepicker";
import { Form, Formik } from "formik";

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
  }

  renderOverView = () => {
    return (
      <OverViewBox>
        <Box className="overHeadingBox">
          <Typography className="overViewTxt">{webConfigJSON.overviewTitle}</Typography>
          <img src={editImage} alt={webConfigJSON.editImageAlt} />
        </Box>
        <Box style={{
          margin: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        }}>
          <Typography className="jobTitle">{webConfigJSON.jobTitle}</Typography>

          <Box className="infoBox">
            <Box className="infoItem">
              <Typography className="label">{webConfigJSON.datesLabel}</Typography>
              <Typography className="value">{webConfigJSON.datesValue}</Typography>
            </Box>
            <Box className="infoItem">
              <Typography className="label">{webConfigJSON.budgetLabel}</Typography>
              <Typography className="value">{webConfigJSON.budgetValue}</Typography>
            </Box>
          </Box>

          <Typography className="description">
            {webConfigJSON.dummyText}
          </Typography>
          <Box>
            <Typography className="attachmentsTitle">{webConfigJSON.attachmentsTitle}</Typography>
            <AttachmentContainer>
              {this.state.files.map((file, index) => (
                <Box className="fileBox" key={index}>
                  <Box className="filePreview">
                    <img src={file.type === "pdf" ? pdfType : imageType} />
                  </Box>
                  <Typography className="fileName">{file.name}</Typography>
                </Box>
              ))}

              <label className="uploadLabel">
                <input
                  accept=".jpg,.jpeg,.pdf,.doc,.svg,.png"
                  type="file"
                  onChange={this.handleFileChange}
                  style={{ display: "none" }}
                  data-test-id="fileChangeTextId"
                />
                <AddIcon className="addIcon" />
              </label>
            </AttachmentContainer>
          </Box>
        </Box>
      </OverViewBox>
    );
  };

  renderRoles = () => {
    return (
      <RolesBox>
        <Box className="roleHeadingBox">
          <Typography className="roleViewTxt">{webConfigJSON.rolesTitle}</Typography>
          <AddIcon className="addIcon" />
        </Box>

        <Box className="roleInnerBox">
          <Typography className="createRoleText">{webConfigJSON.createRoleText}</Typography>
          <Typography className="roleDescription">{webConfigJSON.dummyText}</Typography>
          <Button data-test-id="createTestId" className="createBtn" onClick={this.onCreateClick}>{webConfigJSON.createButtonText}</Button>
        </Box>
      </RolesBox>
    );
  };

  renderCreateRole = () => {
    return (
      <CreateRolesBox>
        <Box className="createHeadingBox">
          <Box className="headingIconText">
            <FolderOpenIcon />
            <Typography className="createViewTxt">{webConfigJSON.roleName}</Typography>
          </Box>
          <img src={editImage} alt={webConfigJSON.editImageAlt} />
        </Box>
        <Box className="mainInnerBox">
          <Box className="contentBox">
            <img src={userImg} alt="userImg" className="userImg" />
            <Box className="userInfoBox">
              <Box className="roleInfoBox">
                <Box className="roleInfoText">
                  <Typography className="userName">{webConfigJSON.userName}</Typography>
                  <Box className="roleDetails">
                    <Typography className="roleSubText">{webConfigJSON.role}</Typography>
                    <Typography className="roleSubText">{webConfigJSON.timeAgo}</Typography>
                  </Box>
                </Box>
                <Box className="actionBox">
                  <ShareIcon />
                  <Button className="terminateBtn" data-test-id="modalCloseTestId" onClick={this.handleTerminateModal}>Terminate</Button>
                </Box>
              </Box>

              <Box className="infoSection">
                <Box className="infoRow">
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.levelLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.levelValue}</Typography>
                  </Box>
                  <Box className="infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.locationLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.locationValue}</Typography>
                  </Box>
                </Box>

                <Box className="infoRow">
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.typeLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.typeValue}</Typography>
                  </Box>
                  <Box className="infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.professionsLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.professionsValue}</Typography>
                  </Box>
                </Box>

                <Box className="infoRow borderBottom">
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.datesLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.datesValue}</Typography>
                  </Box>
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.rateLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.rateValue}</Typography>
                  </Box>
                  <Box className="infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.budgetLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.budgetValue}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className="descriptionRole">
            {webConfigJSON.dummyText}
          </Typography>

          <Box style={{ margin: "50px 0px", borderTop: "1px solid #DFDFDF" }}>
            <Typography className="roleAttachmentsTitle">{webConfigJSON.attachmentsTitle}</Typography>
            <RoleAttachmentContainer>
              {this.state.roleAttachmentFiles.map((file, index) => (
                <Box className="roleAttachmentFileBox" key={index}>
                  <Box className="roleAttachmentFilePreview">
                    <img src={file.type === "pdf" ? pdfType : imageType} />
                  </Box>
                  <Typography className="roleAttachmentFileName">{file.name}</Typography>
                </Box>
              ))}

              <label className="roleAttachmentUploadLabel">
                <input
                  accept=".jpg,.jpeg,.pdf,.doc,.svg,.png"
                  type="file"
                  onChange={this.handleRoleAttachmentFile}
                  style={{ display: "none" }}
                  data-test-id="roleAttachmentFileChangeTextId"
                />
                <AddIcon className="addIcon" />
              </label>
            </RoleAttachmentContainer>
          </Box>

          <Box style={{ borderTop: "1px solid #DFDFDF" }}>
            <Typography className="roleInvoiceTitle">{webConfigJSON.invoicesTitle}</Typography>
            <RoleInvoiceContainer>
              {this.state.roleInvoiceFiles.map((file, index) => (
                <Box className="roleInvoiceFileBox" key={index}>
                  <Box className="roleInvoiceFilePreview">
                    <img src={file.type === "pdf" ? pdfType : imageType} />
                  </Box>
                  <Typography className="roleInvoiceFileName">{file.name}</Typography>
                </Box>
              ))}

              <label className="roleInvoiceUploadLabel">
                <input
                  accept=".jpg,.jpeg,.pdf,.doc,.svg,.png"
                  type="file"
                  onChange={this.handleRoleInvoiceFile}
                  style={{ display: "none" }}
                  data-test-id="roleInvoiceFileChangeTextId"
                />
                <AddIcon className="addIcon" />
              </label>
            </RoleInvoiceContainer>
          </Box>
        </Box>
      </CreateRolesBox>
    );
  };

  renderDeliverable = () => {
    return (
      <DeliverableBox>
        <Box className="createHeadingBox">
          <Typography className="createViewTxt">{webConfigJSON.deliverablesHeading}</Typography>
        </Box>
        <Box style={{ margin: "20px 40px" }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{webConfigJSON.deliverable}</TableCell>
                  <TableCell>{webConfigJSON.status}</TableCell>
                  <TableCell>{webConfigJSON.action}</TableCell>
                  <TableCell>{webConfigJSON.actionee}</TableCell>
                  <TableCell>{webConfigJSON.deadline}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableRowData.map((rowValue, index) => (
                  <React.Fragment key={index}>
                    <TableRow onClick={() => this.handleRowClick(index)} data-test-id={`rowCLickTestid_${index}`}>
                      <TableCell className="rowsValue">{rowValue.name}</TableCell>
                      <TableCell className="rowsValue">
                        <Box className="statusBox">
                          <Typography>{rowValue.status}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box className="actionBox">
                          <Typography>{rowValue.actions}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box className="actioneeBox">
                          <img style={{ width: "30px", height: "30px" }} src={rowValue.actioneeImg} alt="Actionee" />
                          <Typography>{rowValue.actioneeName}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography>{rowValue.deadline}</Typography>
                          <img src={editImage} alt={webConfigJSON.editImageAlt}  id="dialog" data-test-id="showDialogID"  onClick={this.showDialog}/>
                          <StyledShareChatDial
                            open={this.state.openDialog}
                            onClose={this.handleClose}
                            data-test-id="handleCloseID"
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <StyledDialogTitle id="alert-dialog-title">
                              <Box className="headding">
                              Edit Deliverable
                              </Box>
                            </StyledDialogTitle>
                            <StyledDialogContent>
                              <Box>
                                <Typography className="textStyle">Deliverable Name <span>*</span></Typography>
                                <TextField id="outlined-basic" variant="outlined" className="inputStyle"/>
                              </Box>
                              <Box style={{display:"flex",paddingTop:"20px"}}>
                                 <Box className="dateStyle">
                                 <Typography className="textStyle">Deadline <span>*</span></Typography>
                                  <DatePicker
                                  className="datepickerStyle"
                                    customInput={
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <InputAdornment position="start">
                                          <IconButton onClick={this.toggleCalendar}>
                                            <CalendarTodayIcon style={{ fontSize: 20 }} />
                                          </IconButton>
                                        </InputAdornment>
                                        <input
                                          value={this.formatDate(this.state.selectedDate)}
                                          type="text"
                                          onClick={this.toggleCalendar}
                                        />
                                      </div>
                                    }
                                    selected={this.state.selectedDate}
                                    onChange={this.handleDateChange}
                                    open={this.state.isCalendarOpen}
                                    data-test-id="datechange"
                                  />
                                </Box>
                    
                                 <Box style={{width:"358px"}}>
                                 <Typography className="textStyle">Fee <span>*</span></Typography>
                                 <TextField id="outlined-basic" variant="outlined" className="inputStyle1"/>
                                 </Box>
                              </Box>
                              <Box style={{paddingTop:"20px"}}>
                                <Typography className="textStyle">Description  <span>*</span></Typography>
                                <TextareaAutosize
                                  className="textAreaStyle"
                                  aria-label="minimum height"
                                  data-test-id="shareChat"
                                />
                              </Box>
                            </StyledDialogContent>
                            <DialogActions
                              style={{
                                padding: "40px 41px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box display="flex" alignItems="center">
                                <Typography className="textStyle">
                                  Remove Deliverable
                                </Typography>
                                <DeleteOutlineOutlinedIcon />
                              </Box>

                              <Box>
                                <Button
                                  className="btn1"
                                  variant="outlined"
                                  onClick={this.handleClose}
                                  style={{ marginRight: "8px" }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className="btn2"
                                  variant="contained"
                                  onClick={this.handleClose}
                                >
                                  Save
                                </Button>
                              </Box>
                            </DialogActions>
                          </StyledShareChatDial>
                        </Box>
                      </TableCell>
                    </TableRow>

                    {this.state.expandedRowIndex === index && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <AccordionDetails>
                            <Typography>{webConfigJSON.dummyTextAccordion}</Typography>
                          </AccordionDetails>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DeliverableBox>
    );
  };

  renderTerminateModal = () => {
    const { openTerminateModal } = this.state;
    return (
      <TerminateModal open={openTerminateModal} onClose={this.handleTerminateModal}>
        <Box className="modalInsideBox">
          <Box className="header">
            <Typography className="headerText">{webConfigJSON.terminateText}</Typography>
          </Box>
          <Box className="content">
            <Typography className="contentText">{webConfigJSON.areYouSureText}</Typography>
          </Box>
          <Box className="actionButtons">
            <Button className="cancelBtn" onClick={this.handleTerminateModal}>
              {webConfigJSON.cancelTxt}
            </Button>
            <Button className="yesBtn" onClick={this.handleTerminateModal}>
              {webConfigJSON.yesTerminateText}
            </Button>
          </Box>
        </Box>
      </TerminateModal>
    );
  };

  renderNewProjectModal = () =>  (
    <StyledShareChatDial
      open={this.state.openProjectModal}
      onClose={this.handleProjectClose}
      data-testid="handleNewProjectID"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">
        <Box className="headding">
          New Project
        </Box>
      </StyledDialogTitle>
      <form role="form" onSubmit={this.handleFormSubmit} autoComplete="off">
        <StyledDialogContent>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography className="textStyle">Banner Image</Typography>
            <AttachmentContainer>
              {this.state.bannerFiles.map((file, index) => (
                <Box className="fileBox" key={index}>
                  <Box className="filePreview">
                    <img 
                    src={file.type === "pdf" ? pdfType : imageType} />
                  </Box>
                  <Typography 
                  className="fileName"
                  >{file.name}
                  </Typography>
                </Box>
              ))}
              <label className="uploadLabel">
                <input
                  style={{ display: "none" }}
                  data-testid="fileChangeTextIdNew"
                  accept=".jpeg,.jpg,.pdf,.doc,.svg,.png"
                  type="file"
                  onChange={(e) => this.handleBannerChange(e, "banner")}
                />
                <AddIcon
                 className="addIcon" />
              </label>
            </AttachmentContainer>
          </Box>

          <Grid container spacing={2}>
            <Grid md={8} item>
              <Box>
                <Typography className="textStyle">Project Name <span>*</span></Typography>
                <TextField
                  id="projectName"
                  variant="outlined"
                  className="CustominputStyle"
                  name="projectName"
                  data-testid="name"
                  value={this.state.formData.projectName}
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputBlur}
                  error={!!(this.state.formErrors.projectName && this.state.formTouched.projectName)}
                  helperText={this.state.formTouched.projectName ? this.state.formErrors.projectName : ""}
                />
              </Box>
            </Grid>

            <Grid md={8} item>
              <Box sx={{ marginBottom: "15px" }}>
                <Typography className="textStyle">Description <span>*</span></Typography>
                <TextareaAutosize
                  className={this.state.formErrors.description && this.state.formTouched.description
                    ? 'errorStyle'
                    : 'textAreaStyle'
                  }
                  name="description"
                  data-testid="description"
                  value={this.state.formData.description}
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputBlur}
                />
                {this.state.formErrors.description && this.state.formTouched.description && (
                  <FormHelperText error>{this.state.formErrors.description}</FormHelperText>
                )}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ marginBottom: "15px" }}>
            <Typography className="textStyle">Attachments</Typography>
            <AttachmentContainer>
              {this.state.attachmentFiles.map((file, index) => (
                <Box className="fileBox" key={index}>
                  <Box className="filePreview">
                    <img src={file.type === "pdf" ? pdfType : imageType} />
                  </Box>
                  <Typography className="fileName">{file.name}</Typography>
                </Box>
              ))}

              <label className="uploadLabel">
                <input
                  accept=".jpg,.jpeg,.pdf,.doc,.svg,.png"
                  type="file"
                  onChange={(e) => this.handleBannerChange(e, "attachments")}
                  style={{ display: "none" }}
                  data-test-id="fileChangeTextIdtwo"
                />
                <AddIcon className="addIcon" />
              </label>
            </AttachmentContainer>
          </Box>
        </StyledDialogContent>

        <DialogActions style={{ padding: "40px 41px" }}>
          <Box>
            <Button
              className="cancelBtn"
              variant="outlined"
              onClick={this.handleProjectClose}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={`yesBtn ${!this.state.isFormValid ? 'disabledButton' : ''}`}
              type="submit"
              disabled={!this.state.isFormValid}
            >
              Create
            </Button>
          </Box>
        </DialogActions>
      </form>
    </StyledShareChatDial>
  );

  renderRoleModal = () => {
    return (
      <StyledShareChatDial
        open={this.state.openModal}
        onClose={this.handleRoleClose}
        data-test-id="handleCloseID"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title">
          <Box className="headding">
            Create Role
          </Box>
        </StyledDialogTitle>
          <Formik
            initialValues={{
              title: "",
              type: "On site",
              country: "United Kingdom",
              region: "London",
              town: "Central London",
              level: "",
              profession: "",
              startDate: null,
              endDate: null,
              paymentBy: "",
              hourlyrate: "",
              description: ""
            }}
            onSubmit={(values) => {
              this.showOpenRoleCreated()
            }}
            validationSchema={
              Yup .object().shape({
                title: Yup.string().required('This field is required'),
                type: Yup.string().required('This field is required'),
                country: Yup.string().required('This field is required'),
                region: Yup.string().required('This field is required'),
                town: Yup.string().required('This field is required'),
                level: Yup.string().required('This field is required'),
                profession: Yup.string().required('This field is required'),
                startDate: Yup.date()
                  .nullable()
                  .required('Start date is required'),
                endDate: Yup.date()
                  .nullable()
                  .required('End date is required')
                  .min(Yup.ref('startDate'), 'End date must be after start date'),
                paymentBy: Yup.string().required('This field is required'),
                hourlyrate: Yup.number()
                  .required('This field is required')
                  .positive('Hourly rate must be positive')
                  .typeError('Must be a number'),
                description: Yup.string()
                  .required('This field is required')
                  .min(10, 'Description must be at least 10 characters')
              })
            }
            isInitialValid={false}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              isValid,
              handleSubmit
            }) => (
              <Form >
                <StyledDialogContent>
                  <Box style={{ marginBottom: "15px" }}>
                    <Typography className="textStyle">Role Title <span>*</span></Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="CustominputStyle"
                      name="title"
                      value={values.title}
                      onChange={handleChange("title")}
                      onBlur={handleBlur("title")}
                      error={errors.title && touched.title ? true : false}
                      helperText={errors.title && touched.title ? errors.title : ""}
                    />
                  </Box>

                  <Box style={{ marginBottom: "25px" }}>
                    <Typography className="textStyle">Role Type <span>*</span></Typography>
                    <Select
                      labelId="type-label"
                      id="type-select"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.type && touched.type ? true : false}
                      className="selectStyle"
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value="On site">On site</MenuItem>
                      <MenuItem value="remote">Remote</MenuItem>
                    </Select>
                    {errors.type && touched.type && (
                      <FormHelperText error>{errors.type}</FormHelperText>
                    )}
                  </Box>

                  <Grid container spacing={2} style={{ paddingBottom: "15px" }}>
                    <Grid md={4} item>
                      <Box>
                        <Typography className="textStyle">Country <span>*</span></Typography>
                        <Select
                         disabled={values.type=='remote'}
                          variant="outlined"
                          labelId="country-label"
                          id="country-select"
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.country && touched.country ? true : false}
                          className="selectStyle"
                          fullWidth
                        >
                          <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                          <MenuItem value="USA">USA</MenuItem>
                        </Select>
                        {errors.country && touched.country && (
                          <FormHelperText error>{errors.country}</FormHelperText>
                        )}
                      </Box>
                    </Grid>

                    <Grid md={4} item>
                      <Box>
                        <Typography className="textStyle">Region <span>*</span></Typography>
                        <Select
                        disabled={values.type=='remote'}
                          labelId="region-label"
                          id="region-select"
                          name="region"
                          value={values.region}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          error={errors.region && touched.region ? true : false}
                          className="selectStyle"
                          fullWidth
                        >
                          <MenuItem value="London">London</MenuItem>
                          <MenuItem value="Manchester">Manchester</MenuItem>
                        </Select>
                        {errors.region && touched.region && (
                          <FormHelperText error>{errors.region}</FormHelperText>
                        )}
                      </Box>
                    </Grid>

                    <Grid md={4} item>
                      <Box>
                        <Typography className="textStyle">Town <span>*</span></Typography>
                        <Select
                          disabled={values.type=='remote'}
                        variant="outlined"
                          labelId="town-label"
                          id="town-select"
                          name="town"
                          value={values.town}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.town && touched.town ? true : false}
                          className="selectStyle"
                          fullWidth
                        >
                          <MenuItem value="Central London">Central London</MenuItem>
                          <MenuItem value="West London">West London</MenuItem>
                        </Select>
                        {errors.town && touched.town && (
                          <FormHelperText error>{errors.town}</FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>



                  <Box style={{ marginBottom: "25px" }}>
                    <Typography className="textStyle">Level <span>*</span></Typography>
                    <Select
                      labelId="level-label"
                      id="level-select"
                      name="level"
                      value={values.level}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.level && touched.level ? true : false}
                      className="selectStyle"
                      fullWidth
                    >
                      <MenuItem value="Junior">Junior</MenuItem>
                      <MenuItem value="Mid">Mid Level</MenuItem>
                      <MenuItem value="Senior">Senior</MenuItem>
                      <MenuItem value="Lead">Lead</MenuItem>
                    </Select>
                    {errors.level && touched.level && (
                      <FormHelperText error>{errors.level}</FormHelperText>
                    )}
                  </Box>

                  <Box style={{ marginBottom: "25px" }}>
                    <Typography className="textStyle">Professions <span>*</span></Typography>
                    <Select
                      labelId="profession-label"
                      id="profession-select"
                      name="profession"
                      value={values.profession}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      error={errors.profession && touched.profession ? true : false}
                      className="selectStyle"
                      fullWidth
                    >
                      <MenuItem value="Developer">Developer</MenuItem>
                      <MenuItem value="Designer">Designer</MenuItem>
                      <MenuItem value="Product Manager">Product Manager</MenuItem>
                      <MenuItem value="Project Manager">Project Manager</MenuItem>
                    </Select>
                    {errors.profession && touched.profession && (
                      <FormHelperText error>{errors.profession}</FormHelperText>
                    )}
                  </Box>

                  <Grid container spacing={2} style={{ padding: "25px 0" }}>
                    <Grid md={4} item>
                      <Box className="dateStyle">
                        <Typography className="textStyle">Start Date <span>*</span></Typography>
                        <DatePicker
                          selected={values.startDate}
                          onChange={(date) => setFieldValue('startDate', date)}
                          className={`datepickerStyle`}
                          customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <InputAdornment position="start">
                                <IconButton>
                                  <CalendarTodayIcon style={{ fontSize: 20, color:"#000000" }} />
                                </IconButton>
                              </InputAdornment>
                              <input
                                value={values.startDate ? this.formatDate(values.startDate) : ''}
                                onBlur={handleBlur('startDate')}
                              />
                            </div>
                          }
                        />
                        {errors.startDate && touched.startDate && (
                          <FormHelperText error>{errors.startDate}</FormHelperText>
                        )}
                      </Box>
                    </Grid>

                    <Grid md={4} item>
                      <Box className="dateStyle">
                        <Typography className="textStyle">End Date <span>*</span></Typography>
                        <DatePicker
                          selected={values.endDate}
                          onChange={(date) => setFieldValue('endDate', date)}
                          className={`datepickerStyle`}
                          customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <InputAdornment position="start">
                                <IconButton >
                                  <CalendarTodayIcon style={{ fontSize: 20, color:"#000000" }} />
                                </IconButton>
                              </InputAdornment>
                              <input
                                value={values.endDate ? this.formatDate(values.endDate) : ''}
                                onBlur={handleBlur('endDate')}
                              />
                            </div>
                          }
                        />
                        {errors.endDate && touched.endDate && (
                          <FormHelperText error>{errors.endDate}</FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid md={8} item>
                      <Box>
                        <Typography className="textStyle">Payment by <span>*</span></Typography>
                        <Select
                          labelId="payment-label"
                          id="payment-select"
                          name="paymentBy"
                          value={values.paymentBy}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          fullWidth
                          error={errors.paymentBy && touched.paymentBy ? true : false}
                          className="selectStyle"
                        >
                          <MenuItem value="Hour">Deliverable based (fixed cost)</MenuItem>
                          <MenuItem value="Project">Time based (hourly rate)</MenuItem>
                        </Select>
                        {errors.paymentBy && touched.paymentBy && (
                          <FormHelperText error>{errors.paymentBy}</FormHelperText>
                        )}
                      </Box>
                    </Grid>

                    <Grid md={8} item>
                      <Box>
                        <Typography className="textStyle">Hourly Rate <span>*</span></Typography>
                        <TextField
                          id="hourlyrate"
                          variant="outlined"
                          className="CustominputStyle"
                          name="hourlyrate"
                          value={values.hourlyrate}
                          onChange={handleChange("hourlyrate")}
                          onBlur={handleBlur("hourlyrate")}
                          error={errors.hourlyrate && touched.hourlyrate ? true : false}
                          helperText={errors.hourlyrate && touched.hourlyrate ? errors.hourlyrate : ""}
                        />
                      </Box>
                    </Grid>
                    <Grid md={8} item>
                      <Box>
                        <Typography 
                        className="textStyle">
                          Description <span>*</span>
                          </Typography>
                        <TextareaAutosize
                         value={values.description}
                         onChange={handleChange("description")}
                         onBlur={handleBlur("description")}
                          className={errors.description && touched.description ? 'errorStyle' : 'textAreaStyle'}
                          name="description"
                        />
                        {errors.description && touched.description && (
                          <FormHelperText error>
                            {errors.description}
                            </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </StyledDialogContent>
                <DialogActions style={{ padding: "40px 41px" }}>
                  <Box>
                    <Button
                     variant="outlined"
                     onClick={this.handleRoleClose}
                     style={{ marginRight: "8px" }}
                      className="cancelBtn"
                    >
                      Cancel
                    </Button>
                  <Button
                    type="submit"
                    disabled={!isValid}
                    variant="contained"
                    className={`yesBtn ${!isValid ? 'disabledButton' : ''}`}
                  >
                      Create
                    </Button>
                  </Box>
                </DialogActions>
              </Form>
            )}
          </Formik>
      </StyledShareChatDial>
    );
  };

  renderProjCreated = () => (
    <StyledShareApproveDial
      open={this.state.openProjCreated}
      // onClose={this.handleProjectCreateClose}
      data-test-id="handleCloseID"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">
        <Box className="headding flexWrap">
          <span>
            Approve / Reject
          </span>
          <CloseIcon onClick={() => this.handleProjectCreateClose()} />
        </Box>
      </StyledDialogTitle>
          <StyledDialogContent>
            <Typography className="textStyle" style={{ textAlign: "center" }}>
            Project ‘Title’ has been created. Please add the first role.
            </Typography>
          </StyledDialogContent>
          <DialogActions style={{ padding: "0 41px 40px 0" }}>
            <Box>
              {/* <Button
                className="cancelBtn"
                variant="outlined"
                onClick={this.handleProjectCreateClose}
                style={{ marginRight: "8px" }}
              >
                Not yet
              </Button> */}
              <Button
                variant="contained"
                className="yesBtn"
                type="submit"
                onClick={this.showRoleDialog}
              >
                Create Role
              </Button>
            </Box>
          </DialogActions>
      
    </StyledShareApproveDial>
  );

 
  renderExploreSection = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row'
          },
          marginBottom: "40px"
        }}>
        <TopSection sx={{
          flex: 1,
          mb: { xs: 2, md: 0 },
          mr: { xs: 0, md: 5 }
        }}>
          <Box className='boxStyle'>
            <Typography className='heading'>Projects</Typography>
            <Typography className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis.</Typography>
            <MuiButton >Create</MuiButton>
          </Box>
        </TopSection>
        <TopSection sx={{ flex: 1 }}>
          <Box className='boxStyle'>
            <Typography className='heading'>Events</Typography>
            <Typography className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis.</Typography>
            <MuiButton >Create</MuiButton>
          </Box>
        </TopSection>
      </Box>
    )
  }

  renderAproveModal = () => (
    <StyledShareApproveDial
      open={this.state.openApproveModal}
      onClose={this.handleApproveClose}
      data-test-id="handleCloseID"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">
        <Box className="headding">Approve/Reject</Box>
      </StyledDialogTitle>
          <StyledDialogContent>
            <Typography className="textStyle" style={{ textAlign: "center" }}>
            Are the project deliverables approved?
            </Typography>
          </StyledDialogContent>
          <DialogActions style={{ padding: "0 41px 40px 0" }}>
            <Box>
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={this.handleApproveClose}
                style={{ marginRight: "8px" }}
              >
                Not yet
              </Button>
              <Button
                variant="contained"
                className="yesBtn"
                type="submit"
                onClick={this.showApproveDialogTwo}
              >
                Yes
              </Button>
            </Box>
          </DialogActions>
    </StyledShareApproveDial>
  );

  renderAproveTwoModal = () => (
    <StyledShareApproveDial
      open={this.state.openApproveTwoModal}
      onClose={this.handleApproveCloseTwo}
      data-test-id="handleCloseID"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">
        <Box className="headding flexWrap">
          <span>
            Approve/Reject
          </span>
          <CloseIcon onClick={this.handleApproveCloseTwo} />
        </Box>
      </StyledDialogTitle>
      {this.state.openApproveTwoModal ?
        <Formik
          initialValues={{
            amendmants: "",
          }}
          onSubmit={(values) => {
          }}
          validationSchema={
            Yup.object().shape({
              amendmants: Yup.string()
                .min(5, 'min')
                .max(500, '')
            })
          }
          isInitialValid={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid
          }) => (
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <StyledDialogContent>
                <Box>
                  <Typography className="textStyle">Amendments required<span>*</span></Typography>
                  <TextareaAutosize
                    className={errors.amendmants && touched.amendmants ? 'errorStyle' : 'textAreaStyle'}
                    name="amendmants"
                    placeholder="Write your explanations"
                    value={values.amendmants}
                    onChange={handleChange("amendmants")}
                    onBlur={handleBlur("amendmants")}
                  />
                  {errors.amendmants && touched.amendmants && (
                    <FormHelperText error>{errors.amendmants}</FormHelperText>
                  )}
                </Box>
                <Typography className="textMuted">minimum 5 characters, max 500 characters</Typography>
              </StyledDialogContent>

              <DialogActions style={{ padding: "0px 40px 0px 0px" }}>
                <Box>
                  <Button
                    className="cancelBtn"
                    variant="outlined"
                    onClick={this.handleApproveCloseTwo}
                    style={{ marginRight: "8px" }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    className={`yesBtn ${!isValid ? 'disabledButton' : ''}`}
                    type="submit"
                    disabled={!isValid}
                  >
                    Send
                  </Button>
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
        :
        <>
          <StyledDialogContent>
            <Typography className="textStyle" style={{ textAlign: "center" }}>
              Are the project deliverables approved?
            </Typography>
          </StyledDialogContent>
          <DialogActions style={{ padding: "0 41px 40px 0" }}>
            <Box>
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={this.handleApproveCloseTwo}
                style={{ marginRight: "8px" }}
              >
                Not yet
              </Button>
              <Button
                variant="contained"
                className="yesBtn"
                type="submit"
                onClick={() => this.showApproveDialogTwo}
              >
                Yes
              </Button>
            </Box>
          </DialogActions>
        </>
      }
    </StyledShareApproveDial>
  );

  renderDeliverableModal = () => {
    return (
      <StyledShareChatDial
        open={this.state.roleDeliverable}
        onClose={this.closeDeliverable}
        data-test-id="handleCloseID"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title">
          <Box className="headding">
          Create Deliverable
          </Box>
        </StyledDialogTitle>
          <Formik
            initialValues={{
              title: "",
              deadline: null,
              fee: "",
              description: ""
            }}
            onSubmit={(values) => {
              this.showCreateAnother()
            }}
            validationSchema={
              Yup .object().shape({
                title: Yup.string().required('This field is required'),
               
                deadline: Yup.date()
                  .nullable()
                  .required('date is required'),
     
                fee: Yup.number()
                  .required('This field is required')
                  .positive('fee value must be positive')
                  .typeError('Must be a number'),
                description: Yup.string()
                  .required('This field is required')
                  .min(10, 'Description must be at least 10 characters')
              })
            }
            isInitialValid={false}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              isValid,
            }) => (
              <Form >
                <StyledDialogContent>
                  <Box style={{ marginBottom: "15px" }}>
                    <Typography className="textStyle">Deliverable Name<span>*</span></Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="CustominputStyle"
                      name="title"
                      value={values.title}
                      onChange={handleChange("title")}
                      onBlur={handleBlur("title")}
                      error={errors.title && touched.title ? true : false}
                      helperText={errors.title && touched.title ? errors.title : ""}
                    />
                  </Box>

                  <Grid container spacing={2} style={{ padding: "25px 0" }}>
                    <Grid md={4} item>
                      <Box className="dateStyle">
                        <Typography className="textStyle">Deadline <span>*</span></Typography>
                        <DatePicker
                          selected={values.deadline}
                          onChange={(date) => setFieldValue('deadline', date)}
                          className={`datepickerStyle ${errors.deadline && touched.deadline ? 'error' : ''}`}
                          customInput={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <InputAdornment position="start">
                                <IconButton>
                                  <CalendarTodayIcon style={{ fontSize: 20 }} />
                                </IconButton>
                              </InputAdornment>
                              <input
                                value={values.deadline ? this.formatDate(values.deadline) : ''}
                                onBlur={handleBlur('deadline')}
                              />
                            </div>
                          }
                        />
                        {errors.deadline && touched.deadline && (
                          <FormHelperText error>{errors.deadline}</FormHelperText>
                        )}
                      </Box>
                    </Grid>

                    <Grid md={4} item>
                      <Box className="dateStyle">
                        <Typography className="textStyle">Fee <span>*</span></Typography>
                        <TextField
                          id="hourlyrate"
                          variant="outlined"
                          className="CustominputStyle"
                          name="hourlyrate"
                          value={values.fee}
                          onChange={handleChange("fee")}
                          onBlur={handleBlur("fee")}
                          error={errors.fee && touched.fee ? true : false}
                          helperText={errors.fee && touched.fee ? errors.fee : ""}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid md={12} item>
                      <Box>
                        <Typography className="textStyle">Description <span>*</span></Typography>
                        <TextareaAutosize
                          className={errors.description && touched.description ? 'errorStyle' : 'textAreaStyle'}
                          name="description"
                          value={values.description}
                          onChange={handleChange("description")}
                          onBlur={handleBlur("description")}
                        />
                        {errors.description && touched.description && (
                          <FormHelperText error>{errors.description}</FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </StyledDialogContent>

                <DialogActions style={{ padding: "40px 41px" }}>
                  <Box>
                    <Button
                      className="cancelBtn"
                      variant="outlined"
                      onClick={this.closeDeliverable}
                      style={{ marginRight: "8px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className={`yesBtn ${!isValid ? 'disabledButton' : ''}`}
                      type="submit"
                      disabled={!isValid}
                    >
                      Create
                    </Button>
                  </Box>
                </DialogActions>
              </Form>
            )}
          </Formik>
      </StyledShareChatDial>
    );
  };

  renderRoleCreated = () => (
    <StyledShareApproveDial
      open={this.state.openRoleCreated}
      onClose={this.handleRoleCreateClose}
      data-test-id="handleCloseID"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">
        <Box className="headding flexWrap">
          <span>
            Role Created
          </span>
          <CloseIcon onClick={() => this.handleRoleCreateClose()} />
        </Box>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography className="textStyle" style={{ textAlign: "center" }}>
          Role ‘Title’ has been created. Please add the first deliverable.
        </Typography>
      </StyledDialogContent>
      <DialogActions style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '0px 0px 40px 0px'
      }}>
        <Box >
          <Button
            variant="contained"
            className="yesBtn"
            type="submit"
            onClick={this.showDeliverable}
          >
            Create Deliverable
          </Button>
        </Box>
      </DialogActions>

    </StyledShareApproveDial>
  );

  renderCreateAnother = ()=> (
    <StyledShareApproveDial
      open={this.state.openCreateAnother}
      // onClose={this.handleProjectCreateClose}
      data-test-id="handleCloseID"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">
        <Box className="headding flexWrap">
          <span>
          Create Another Deliverable
          </span>
          <CloseIcon onClick={this.closeCreateAnother} />
        </Box>
      </StyledDialogTitle>
          <StyledDialogContent>
            <Typography className="textStyle" style={{ textAlign: "center" }}>
            Would you like to create another deliverable?
            </Typography>
          </StyledDialogContent>
          <DialogActions style={{ padding: "0 41px 40px 0" }}>
            <Box>
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={this.closeCreateAnother}
                style={{ marginRight: "8px" }}
              >
                No, post the role
              </Button>
              <Button
                variant="contained"
                className="yesBtn"
                type="submit"
                onClick={this.closeCreateAnother}
              >
                Yes, Create
              </Button>
            </Box>
          </DialogActions>
    </StyledShareApproveDial>
  );



  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <HeaderNavbar navigation={this.props.navigation} data-test-id="headerNavbar" />
        {!this.state.showDetailSection?
        <StyledContainer>
          <Container maxWidth="xl" className="parent">
            <Typography className="headding">Dashboard</Typography>
            <Box style={{ textAlign: "center" }}>
              <p className="pera">You currently have no active projects.</p>
              <Button style={{marginBottom:"50px"}} data-testid="create-project" onClick={this.showProjectDialog}>Create new project</Button>
                {this.renderNewProjectModal()}
                {this.renderProjCreated()}
                {this.renderRoleModal()}

                {this.renderAproveModal()}
                {this.renderAproveTwoModal()} 
                {this.renderRoleCreated()}
                {this.renderDeliverableModal()}
                {this.renderCreateAnother()}
            </Box>
            <Box>
            {this.renderExploreSection()}
              <DashboradProject showApproveDialog={this.showApproveDialog} data-test-id="navigateToDetails" navigateToDetailSection={this.navigateToDetailSection}/>
            </Box>
          </Container>
        </StyledContainer>
        : <Box>
        {this.state.isCreate ? 
              <>
                <HeadingBox>
                  <Typography className="hideText" onClick={this.navigateToDashboard}>Dashboard</Typography>
                  <Typography className="hideText">{">"}</Typography>
                  <Typography className="hideText" onClick={this.onCreateClick}>0002 Truckers</Typography>
                  <Typography className="hideText">{">"}</Typography>
                  <Typography className="showText">Automotive Designer</Typography>
                </HeadingBox>
                {this.renderCreateRole()}
                {this.renderDeliverable()}
                {this.renderTerminateModal()}
              </>
              :
              (
                <>
                  <HeadingBox>
                    <Typography className="hideText" onClick={this.navigateToDetailSection}>Dashboard</Typography>
                    <Typography className="hideText">{">"}</Typography>
                    <Typography className="showText">0002 Truckers</Typography>
                  </HeadingBox>
                  {this.renderOverView()}
                  {this.renderRoles()}
                </>
          )
        }
      </Box>}
      </>

     
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const HeadingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  margin: "20px",
  "@media (min-width: 600px)": {
    margin: "30px"
  },
  "@media (min-width: 960px)": {
    margin: "50px"
  },
  "@media (min-width: 1280px)": {
    margin: "70px"
  },

  "& .hideText": {
    color: "#9B9B9D",
    fontWeight: 500,
    fontSize: "16px",

    "@media (min-width: 600px)": {
      fontSize: "18px"
    },
    "@media (min-width: 960px)": {
      fontSize: "22px"
    },
    "@media (min-width: 1280px)": {
      fontSize: "30px"
    }
  },

  "& .showText": {
    color: "#0D0C22",
    fontWeight: 500,
    fontSize: "16px",
    "@media (min-width: 600px)": {
      fontSize: "18px"
    },
    "@media (min-width: 960px)": {
      fontSize: "22px"
    },
    "@media (min-width: 1280px)": {
      fontSize: "30px"
    }
  },
});

const OverViewBox = styled(Box)({
  margin: "50px 70px 0px 70px",
  border: "1px solid #DFDFDF",
  "& .overHeadingBox": {
    backgroundColor: "#DFDFDF",
    height: "50px",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .overViewTxt": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Silika-Medium"
  },
  "& .jobTitle": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium"
  },
  "& .infoBox": {
    display: "flex",
    flexDirection: "row",
    gap: "60px",
    borderBottom: "1px solid #DFDFDF",
    borderTop: "1px solid #DFDFDF",
    padding: "10px 0px"
  },
  "& .infoItem": {
    display: "flex",
    gap: "15px"
  },
  "& .label": {
    color: "#9B9B9D",
    fontWeight: 500,
    fontSize: "12px",
    fontFamily: "Silika-Medium"
  },
  "& .value": {
    color: "#010101",
    fontWeight: 500,
    fontSize: "13px",
    fontFamily: "Silika-Medium",
    borderBottom: "2px solid #010101",
    display: "inline-block",
    paddingBottom: "2px"
  },
  "& .description": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-light",
    height: "120px",
    borderBottom: "1px solid #DFDFDF",
    marginTop: "10px"
  },
  "& .attachmentsTitle": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium",
    marginBottom: "20px"
  },
  "& .addIcon": {
    color: "#9B9B9B"
  },
  "@media (max-width: 768px)": {
    margin: "0px 20px",

    "& .overViewTxt": {
      fontSize: "12px"
    },
    "& .jobTitle": {
      fontSize: "20px"
    },
    "& .infoBox": {
      flexDirection: "column",
      gap: "20px"
    },
    "& .infoItem": {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%"
    },
    "& .label": {
      fontSize: "10px"
    },
    "& .value": {
      fontSize: "11px"
    },
    "& .description": {
      height: "auto"
    },
    "& .attachmentsTitle": {
      fontSize: "20px"
    }
  },
  "@media (max-width: 480px)": {
    margin: "0px 10px",
    "& .overHeadingBox": {
      height: "30px",
      padding: "0px 5px"
    },
    "& .overViewTxt": {
      fontSize: "10px"
    },
    "& .jobTitle": {
      fontSize: "18px"
    },
    "& .infoBox": {
      flexDirection: "column",
      gap: "15px"
    },
    "& .infoItem": {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%"
    },
    "& .label": {
      fontSize: "9px"
    },
    "& .value": {
      fontSize: "10px"
    },
    "& .description": {
      height: "auto",
      fontSize: "12px"
    },
    "& .attachmentsTitle": {
      fontSize: "18px"
    }
  }
});

const AttachmentContainer = styled(Box)({
  gap: "10px",
  display: "flex",
  overflow: "scroll",
  "& .fileBox": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "70px"
  },
  "& .filePreview": {
    height: "70px",
    borderRadius: "8px",
    width: "70px",
    border: "1px solid black",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    backgroundColor: "#FFFFFF",
    cursor: "pointer"
  },
  "& .fileName": {
    width: "70px",
    fontWeight: 400,
    color: "#000000",
    fontSize: "10px",
    fontFamily: "Silika-Medium"
  },
  "& .uploadLabel": {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    height: "70px",
    width: "70px",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    border: "1px dashed #9B9B9B",
    cursor: "pointer"
  },
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "15px",
    "& .fileBox, & .uploadLabel": {
      height: "70px",
      width: "70px"
    },
    "& .fileName": {
      fontSize: "11px"
    }
  },
  "@media (max-width: 480px)": {
    flexDirection: "column",
    "& .fileBox, & .uploadLabel": {
      height: "60px",
      width: "60px"
    },
    "& .fileName": {
      fontSize: "10px"
    }
  }
});

const RolesBox = styled(Box)({
  margin: "70px 70px 50px 70px",
  border: "1px solid #DFDFDF",
  "& .roleHeadingBox": {
    backgroundColor: "#DFDFDF",
    height: "50px",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .roleInnerBox": {
    padding: "50px",
    display: "flex",
    gap: "20px",
    flexDirection: "column"
  },
  "& .roleViewTxt": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Silika-Medium"
  },
  "& .createRoleText": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium",

    "@media (max-width: 768px)": {
      fontSize: "20px"
    },
    "@media (max-width: 480px)": {
      fontSize: "18px"
    }
  },
  "& .roleDescription": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 768px)": {
      fontSize: "12px"
    },
    "@media (max-width: 480px)": {
      fontSize: "12px"
    }
  },
  "& .createBtn": {
    textTransform: "unset",
    width: "105px",
    backgroundColor: "#0D0C22",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    cursor: "pointer",
    borderRadius: "1px",
    "&:hover": {
      backgroundColor: "#0D0C22",
      color: "#FFFFFF"
    },
    "@media (max-width: 480px)": {
      width: "100%",
      fontSize: "14px",
      marginTop: "10px"
    }
  },
  "@media (max-width: 768px)": {
    margin: "30px",
    "& .roleInnerBox": {
      padding: "30px"
    }
  },
  "@media (max-width: 480px)": {
    margin: "20px",
    "& .roleInnerBox": {
      flexDirection: "column",
      padding: "20px"
    },
    "& .roleHeadingBox": {
      height: "40px"
    }
  }
});

const CreateRolesBox = styled(Box)({
  margin: "70px",
  border: "1px solid #DFDFDF",

  "@media (max-width: 1000px)": {
    margin: "20px"
  },

  "@media (max-width: 480px)": {
    margin: "10px"
  },
  "& .createHeadingBox": {
    backgroundColor: "#DFDFDF",
    height: "50px",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 900px)": {
      height: "45px",
      padding: "0px 15px"
    },
    "@media (max-width: 768px)": {
      height: "40px",
      padding: "0px 10px"
    },
    "@media (max-width: 480px)": {
      height: "35px",
      padding: "0px 5px"
    }
  },
  "& .headingIconText": {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  "& .createViewTxt": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "16px"
    },
    "@media (max-width: 768px)": {
      fontSize: "15px"
    },
    "@media (max-width: 480px)": {
      fontSize: "14px"
    }
  },
  "& .roleAttachmentsTitle": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium",
    margin: "15px 0px"
  },
  "& .roleInvoiceTitle": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium",
    margin: "15px 0px"
  },
  "& .contentBox": {
    display: "flex",
    "@media (max-width: 900px)": {
      flexDirection: "row"
    },
    "@media (max-width: 768px)": {
      flexDirection: "column"
    },
    "@media (max-width: 480px)": {
    }
  },

  "& .mainInnerBox": {
    margin: "40px",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 900px)": {
      margin: "30px"
    },
    "@media (max-width: 768px)": {
      margin: "20px"
    },
    "@media (max-width: 480px)": {
      margin: "15px"
    }
  },
  "& .userImg": {
    height: "180px",
    width: "180px",
    borderRadius: "50%",
    "@media (max-width: 900px)": {
      height: "150px",
      width: "150px"
    },
    "@media (max-width: 768px)": {
      height: "120px",
      width: "120px",
      margin: "0 auto"
    },
    "@media (max-width: 480px)": {
      height: "100px",
      width: "100px",
      margin: "0 auto"
    }
  },
  "& .userInfoBox": {
    width: "100%",
    marginLeft: "20px",
    "@media (max-width: 900px)": {
      marginLeft: "15px"
    },
    "@media (max-width: 768px)": {
      marginLeft: "0",
      marginTop: "10px"
    }
  },
  "& .roleInfoBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 900px)": {
      gap: "15px"
    },
    "@media (max-width: 768px)": {
      gap: "10px"
    }
  },
  "& .roleDetails": {
    display: "flex",
    gap: "30px",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: "5px"
    }
  },
  "& .actionBox": {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    "@media (max-width: 900px)": {
      gap: "15px"
    },
    "@media (max-width: 480px)": {
      gap: "10px"
    }
  },
  "& .terminateBtn": {
    textTransform: "unset",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "15px",
    fontFamily: "Silika-Medium",
    width: "105px",
    backgroundColor: "#0D0C22",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "10px 0px",
    "@media (max-width: 900px)": {
      width: "95px",
      fontSize: "14px"
    },
    "@media (max-width: 768px)": {
      width: "90px",
      fontSize: "13px"
    },
    "@media (max-width: 480px)": {
      width: "85px",
      fontSize: "12px",
      padding: "8px 0"
    }
  },
  "& .userName": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "20px"
    },
    "@media (max-width: 768px)": {
      fontSize: "18px"
    },
    "@media (max-width: 480px)": {
      fontSize: "16px"
    }
  },
  "& .roleSubText": {
    color: "#9B9B9B",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "13px"
    },
    "@media (max-width: 768px)": {
      fontSize: "12px"
    },
    "@media (max-width: 480px)": {
      fontSize: "11px"
    }
  },
  "& .infoSection": {
    margin: "20px 0px",
    "@media (max-width: 900px)": {
      margin: "15px 0"
    },
    "@media (max-width: 768px)": {
      margin: "10px 0",
      flexDirection: "row"
    },
    "& .infoRow": {
      borderTop: "1px solid #DFDFDF",
      padding: "10px 0px",
      display: "flex",
      gap: "50px",
      "@media (max-width: 900px)": {
        gap: "30px",
      },
      "@media (max-width: 768px)": {
        gap: "10px",
      }
    },
    "& .infoLabel": {
      color: "#9B9B9B",
      fontWeight: 500,
      fontSize: "12px",
      fontFamily: "Silika-Medium",
      "@media (max-width: 900px)": {
        fontSize: "12px",
      },
      "@media (max-width: 768px)": {
        fontSize: "11px",
      },
      "@media (max-width: 480px)": {
        fontSize: "10px",
      }
    },
    "& .infoValue": {
      color: "#000000",
      fontWeight: 500,
      fontSize: "12px",
      fontFamily: "Silika-Medium",
      "@media (max-width: 900px)": {
        fontSize: "12px",
      },
      "@media (max-width: 768px)": {
        fontSize: "11px",
      },
      "@media (max-width: 480px)": {
        fontSize: "10px",
      }
    }
  },
  "& .fixedWidth": {
    width: "100%",
    maxWidth: "180px",
    "@media (max-width: 1024px)": {
      maxWidth: "160px"
    },
    "@media (max-width: 768px)": {
      maxWidth: "140px"
    },
    "@media (max-width: 480px)": {
      maxWidth: "120px"
    }
  },
  "& .borderBottom": {
    borderBottom: "1px solid #DFDFDF"
  },
  "& .infoInsideBox": { display: "flex", flexDirection: "row", gap: "10px" },
  "& .descriptionRole": {
    color: "#000000",
    fontSize: "14px",
    fontFamily: "Silika-light",
    "@media (max-width: 480px)": {
      fontSize: "12px"
    }
  }

});

const RoleAttachmentContainer = styled(Box)({
  gap: "10px",
  display: "flex",
  overflow: "scroll",
  "& .roleAttachmentFileBox": {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "70px"
  },
  "& .roleAttachmentFilePreview": {
    height: "70px",
    width: "70px",
    borderRadius: "8px",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    marginLeft: "10px",
    cursor: "pointer"
  },
  "& .roleAttachmentFileName": {
    fontSize: "10px",
    width: "70px",
    color: "#000000",
    fontWeight: 400,
    fontFamily: "Silika-Medium"
  },
  "& .roleAttachmentUploadLabel": {
    height: "70px",
    width: "70px",
    border: "1px dashed #9B9B9B",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    display: "flex",
    cursor: "pointer"
  },
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "15px",
    "& .roleAttachmentFileName": {
      fontSize: "11px"
    },
    "& .roleAttachmentFileBox, & .roleAttachmentUploadLabel": {
      width: "70px",
      height: "70px"
    },
  },
  "@media (max-width: 480px)": {
    flexDirection: "column",
    "& .roleAttachmentFileName": {
      fontSize: "10px"
    },
    "& .roleAttachmentFileBox, & .roleAttachmentUploadLabel": {
      width: "60px",
      height: "60px"
    },
  }
});

const RoleInvoiceContainer = styled(Box)({
  display: "flex",
  gap: "10px",
  overflow: "scroll",
  "& .roleInvoiceFileBox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "70px"
  },
  "& .roleInvoiceFilePreview": {
    height: "70px",
    width: "70px",
    borderRadius: "8px",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    marginLeft: "10px",
    cursor: "pointer"
  },
  "& .roleInvoiceFileName": {
    width: "70px",
    color: "#000000",
    fontWeight: 400,
    fontSize: "10px",
    fontFamily: "Silika-Medium"
  },
  "& .roleInvoiceUploadLabel": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    width: "70px",
    borderRadius: "5px",
    border: "1px dashed #9B9B9B",
    backgroundColor: "#FFFFFF",
    cursor: "pointer"
  },
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "15px",
    "& .roleInvoiceFileBox, & .roleInvoiceUploadLabel": {
      width: "70px",
      height: "70px"
    },
    "& .roleInvoiceFileName": {
      fontSize: "11px"
    }
  },
  "@media (max-width: 480px)": {
    flexDirection: "column",
    "& .roleInvoiceFileBox, & .roleInvoiceUploadLabel": {
      width: "60px",
      height: "60px"
    },
    "& .roleInvoiceFileName": {
      fontSize: "10px"
    }
  }
});

const DeliverableBox = styled(Box)({
  margin: "70px",
  border: "1px solid #DFDFDF",

  "@media (max-width: 1000px)": {
    margin: "20px"
  },
  "@media (max-width: 480px)": {
    margin: "10px"
  },

  "& .createHeadingBox": {
    backgroundColor: "#DFDFDF",
    height: "50px",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 900px)": {
      height: "45px",
      padding: "0px 15px"
    },
    "@media (max-width: 768px)": {
      height: "40px",
      padding: "0px 10px"
    },
    "@media (max-width: 480px)": {
      height: "35px",
      padding: "0px 5px"
    }
  },

  "& .createViewTxt": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "16px"
    },
    "@media (max-width: 768px)": {
      fontSize: "15px"
    },
    "@media (max-width: 480px)": {
      fontSize: "14px"
    }
  },

  "& .rowsValue": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium"
  },

  "& .statusBox": {
    backgroundColor: "pink",
    width: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "20px",
    padding: "3px 0px"
  },

  "& .actionBox": {
    backgroundColor: "#0D0C22",
    color: "#FFFFFF",
    width: "190px",
    display: "flex",
    alignItems: "center",
    padding: "3px 0px",
    justifyContent: "center"
  },

  "& .actioneeBox": {
    display: "flex",
    gap: "20px",
    flexDirection: "row",
    alignItems: "center"
  },

  "& .MuiTableCell-root": {
    padding: "10px 5px"
  },

  "& .MuiTableCell-head": {
    color: "#000000",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Silika-Medium"
  },
});
const TerminateModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .modalInsideBox": {
    width: "500px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    height: "200px",
    "@media (max-width: 600px)": {
      width: "90%",
      height: "auto"
    }
  },
  "& .header": {
    height: "50px",
    backgroundColor: "#0D0C22",
    display: "flex",
    alignItems: "center",
    padding: "0px 25px",
    flexDirection: "row",
    "@media (max-width: 600px)": {
      padding: "0px 15px"
    }
  },
  "& .headerText": {
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Silika-Medium"
  },
  "& .content": {
    display: "flex",
    padding: "10px"
  },
  "& .contentText": {
    marginTop: "20px",
    color: "#010101",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    textAlign: "center",
    width: "100%"
  },
  "& .actionButtons": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    padding: "10px 25px",
    "@media (max-width: 600px)": {
      padding: "10px 15px"
    }
  },
  "& .cancelBtn": {
    textTransform: "unset",
    color: "#0D0C22",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    backgroundColor: "#FFFFFF",
    padding: "8px 10px",
    border: "1px solid #010101",
    cursor: "pointer",
    borderRadius: "1px"
  },
  "& .yesBtn": {
    textTransform: "unset",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    backgroundColor: "#010101",
    padding: "8px 10px",
    border: "1px solid #010101",
    cursor: "pointer",
    borderRadius: "1px"
  }
});

const TopSection = styled(Box)({
  "& .boxStyle": {
      padding: "41px",
      background: "#FFFFFF",
      border: "1px solid #DFDFDF",
      borderRadius: "4px 0px 0px 0px",
      boxShadow: "0px 10px 25px 0px #00000017",
  },
  "& .heading": {
      fontFamily: "Silika-Medium !important",
      fontSize: "20px !important",
      fontWeight: "500 important",
      lineHeight: "24px",
      color: "#000000",
  },
  "& .content": {
    lineHeight: "24px",
    paddingBottom: "45px",
    paddingTop: "30px",
    color: "#000000",
    fontWeight: 200,
    fontSize: "16px",
    fontFamily: "Silika-ExtraLight",
    marginTop: "10px"
 },
  "& .pb-0":{
    paddingBottom:0
  },
  "& .lineStyle": {
      margin: "10px 0px",
  },
  "& .spanStyle": {
      background: "#FF5959",
      padding: "2px 8px",
      borderRadius: "50%",
      width: "24px",
      color: "#fff",
      fontSize: "16px"
  },
});

const MuiButton = styled(Button)({
  width: "104px !important",
  height: "48px !important",
  padding: "16px",
  borderRadius: "2px",
  backgroundColor: "#0D0C22",
  color: "#FFFFFF",
  fontFamily: "silika-Medium",
  textTransform: 'capitalize',
  '&:hover': {
      backgroundColor: "#0D0C22",
      boxShadow: 'none',
  },
});

// Customizable Area End

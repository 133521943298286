// Customizable Area Start
import React from "react";

import {
    Typography,
    Button,
    Grid,
    TextField,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle } from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, { Props } from "./AccountRegistrationController";
import * as Yup from "yup";
import { Formik } from "formik";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const validationSchema = Yup.object({
})
export default class AddPaymentDetail extends AccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <div style={{ overflow: 'hidden' }}>
                    <LoginSignUp navigation={this.props.navigation} id={""}>
                        <MainSection>
                            <div style={{ ...webStyle.formTitleContainer, justifyContent: 'left', marginBottom: '35px' }}>
                            <Formik
                                initialValues={{
                                 }}
                                data-test-id="formikForm"
                                onSubmit={(values) => { 
                                }}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {({
                                   handleSubmit
                                }) => {
                                    return (
                                        <form style={{ height: '100%', paddingRight: 10 }} autoComplete="off" onSubmit={handleSubmit}>

                                                <div style={{ ...webStyle.formTitleContainer, justifyContent: 'left', marginBottom: '40px' }}>
                                                    <Typography style={{ ...webStyle.formTitleText, textAlign: "left" as "left", fontSize: "28px", fontWeight: 500 }}>
                                                        Payment | £ 80
                                                    </Typography>
                                                    <Typography style={{ ...webStyle.formTitleText, textAlign: "left" as "left", fontWeight: 500 }}>
                                                        Set up payment method
                                                    </Typography>
                                                    <Typography className="peraStyle" style={{ margin: "35px 0px" }}>Set up your payment method, Please fill your details below</Typography>

                                                </div>
                                                <div>
                                                    <Grid container spacing={2} style={{ textAlign: 'left', marginTop: "20px" }}>
                                                        <Grid item xs={12} lg={6} md={6}>
                                                            <Typography className="lableStyle">Name on the Card*</Typography>
                                                            <StyledTextField
                                                                fullWidth
                                                                name={"Jhon Doe"}
                                                                // value={values.firstName}
                                                                inputProps={{ maxLength: 30, style: { height: 10 }, className: "customTextField" }}
                                                                placeholder="Jhon Doe"
                                                                variant="outlined"
                                                                data-test-id='cardName'
                                                                // onChange={handleChange}
                                                                FormHelperTextProps={{
                                                                    className: "formHelperClass"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} md={6}>
                                                            <Typography className="lableStyle">Card Number*</Typography>
                                                            <StyledTextField
                                                                fullWidth
                                                                name={"Card Number"}
                                                                data-test-id='CardNumber'
                                                                // value={values.lastName}
                                                                placeholder="0000 0000 0000 0000 "
                                                                variant="outlined"
                                                                // onChange={handleChange}
                                                                inputProps={{ maxLength: 30, style: { height: 10 }, className: "customTextField" ,inputMode: "numeric" }}
                                                                FormHelperTextProps={{
                                                                    className: "formHelperClass"
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} lg={6} md={6}>
                                                            <Typography className="lableStyle">Expiration Date*</Typography>
                                                            <StyledTextField
                                                                fullWidth
                                                                name={"Expiration Date"}
                                                                data-test-id='CardNumber'
                                                                // value={values.lastName}
                                                                placeholder="Expiration Date"
                                                                variant="outlined"
                                                                // onChange={handleChange}
                                                                inputProps={{ maxLength: 30, style: { height: 10 }, className: "customTextField" }}
                                                                FormHelperTextProps={{
                                                                    className: "formHelperClass"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} md={6}>
                                                            <Typography className="lableStyle">Security Code*</Typography>
                                                            <StyledTextField
                                                                fullWidth
                                                                placeholder="&#x2022;&#x2022;&#x2022;"
                                                                className="marginBottom16"
                                                                data-test-id={"txtPassword"}
                                                                name="password"
                                                                variant="outlined"
                                                                type={this.state.showPassword ? "text" : "password"}
                                                                // value={values.password}
                                                                // onChange={handleChange}
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        marginLeft: 0,
                                                                    },
                                                                    classes: {
                                                                        error: "formHelperErrorText"
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        error: "formError"
                                                                    },
                                                                    endAdornment:
                                                                        !this.state.showPassword
                                                                            ? (<VisibilityOffIcon
                                                                                style={webStyle.passwordVisibilityOffIcon}
                                                                                onClick={this.handlePassShow}
                                                                            />)
                                                                            : (<VisibilityIcon
                                                                                style={webStyle.passwordVisibilityOnIcon}
                                                                                onClick={this.handlePassShow}
                                                                            />)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <NextActionBtn>
                                                    <div>
                                                        <Button className="AddBtnStyle" data-test-id="addPaymentDetailBackID" onClick={() => { this.addPaymentDetailBack() }}>Back</Button>
                                                        <Button className="NextBtnStyle" data-test-id="addPaymentDetailConfirmID" onClick={() => { this.addPaymentDetailConfirm() }}>Confirm</Button>
                                                    </div>
                                                </NextActionBtn>
                                            </form>
                                        )
                                    }}
                            </Formik>
                            </div>
                        </MainSection>
                    </LoginSignUp>
                </div>
            </ThemeProvider>
        );
    }
}
const baseMainSectionStyle = {
    lineHeight: "19.29px",
    fontSize: "16px",
    letterSpacing: "0.02em",
};
const baseNextSectionStyle={
    width: "129px",
    height: "48px",
    padding: "23.04px",
    gap: " 11.52px",
    borderRadius: "2.88px",
    fontFamily: "Silika-Medium",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "18px",
    textTransform: "capitalize",
};
const MainSection = styled("div")({
    marginTop: "108px",
    overflow: "hidden",
    width: "727px",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    "& .peraStyle": {
        ...baseMainSectionStyle,
        fontFamily: "Silika-Light",
        fontWeight: 300,
        marginTop:"5px"
    },
    "& .lableStyle":{
        ...baseMainSectionStyle,
        fontFamily: "Silika-Medium",
        fontWeight: 500,
        margin:"10px 0px"
    }
})
const NextActionBtn = styled('div')({
    marginTop:"40px",
    display: "flex",
    justifyContent: "end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        marginTop:"30px",
    },
    "& .AddBtnStyle": {
      ...baseNextSectionStyle,
      background: "#FFFFFF",
      border: "1.44px solid #010101",
      marginRight: "20px",
    },
    "& .NextBtnStyle": {
    ...baseNextSectionStyle,
    color:"#FFFFFF",
    background: "#0D0C22",
    }
  })
const StyledTextField = styled(TextField)({
    "& .formHelperClass":{
        color: "#DC2626",
        marginLeft: "0px",
        fontFamily: "Silika-Medium"
    },
    "& input, input::placeholder":{
        fontFamily: "Silika-Medium !important"
    }
});
// Customizable Area End
// Customizable Area Start
Object.defineProperty(exports, '__esModule', {
  value: true
});


exports.compnaypagetitle = 'COMPANY PAGE';

exports.getcompanyApiContentType = 'application/json';
exports.getCompanyEndPoint = 'bx_block_joblisting/company_pages';
exports.getcompanyApiMethod = 'GET';
exports.ApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.searchJobApiEndPoint = "bx_block_search/search_jobs";
exports.searchPeopleApiEndPoint = "bx_block_search/search_people";
exports.searchCompanyApiEndPoint = "bx_block_search/search_companies";
exports.jobListingApiEndPoint = "bx_block_joblisting/jobs";
exports.getProfessionsListApiEndPoint="/profession/professions";
exports.getWorkTypesListApiEndPoint="/profession/work_types";
exports.getAllSkilsApiEndPoint="skill/skills";
exports.getSeniorityListApiEndPoint="profession/seniority_level";

exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.exampleAPiEndPoint = 'EXAMPLE_URL';
exports.exampleAPiMethod = 'POST';
exports.exampleApiContentType = 'application/json';
exports.textInputPlaceHolder = 'Enter Text';
exports.labelTitleText = 'JobListing';
exports.labelBodyText = 'JobListing Body';
exports.emptyList = 'No Data Found';
exports.btnExampleTitle = 'CLICK ME';


// Manage people
exports.pageMemberLabel = 'Company page admins';
exports.pageMemberNote = 'View, Edit or Add member to you company page';

// add people
exports.addPeople = 'ADD PEOPLE';

// buttons
exports.continue_btn = 'Continue';
exports.continuetopage = 'Go to company page';
exports.addMember = 'Add New Admin';


// Customizable Area End

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from "@material-ui/core";
import { closeIcon } from "../assets";

interface Iprops{
 handlePopUpOpportunity:()=>void;
 open:boolean;
 handleJobPostPopUp:()=>void
}

const StyledDialogApplyJob = styled(Dialog)({
  "& .MuiDialog-paperFullWidth": {
    maxWidth: "600px",
    height: "406px",
  },
  "& .MuiDialogTitle-root": {
    padding: "15px 40px",
  },
  "& .MuiTypography-h6": {
    fontSize: "16px",
    fontFamily: "Silika-Medium",
  },
  "& .subTitle": {
    fontFamily: "Silika-Light",
    fontSize: "16px",
    fontWeight: 300,
    maxWidth:"480px",
    textAlign: "center",
    marginTop:"30px",
    color:"#010101"
  },
  "& .cancelBtn": {
    textTransform: "none",
    border: "1px solid black",
    borderRadius: "2px",
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    maxWidth: "521px",
    height: "48px",
    marginBottom:"26px"
  },
  "& .yesBtn": {
    width: "112px",
    backgroundColor: "black",
    color: "white",
    marginRight: "32px",
    marginBottom:"15px"
  },
  "& .btnContainer":{
    width:"100%",
    justifyContent:"center",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginTop:"26px"
  },
  "& .opportunitySelectionBtn":{
    width:"521px",
    fontFamily:"silika-medium",
    fontSize:"16px",
   "&:hover":{
    backgroundColor:"black",
    color:"white"
   }
  }
});

export const DialogOpportunity = ({open,handlePopUpOpportunity,handleJobPostPopUp}:Iprops) => {
  return (
    <StyledDialogApplyJob
      fullWidth={true}
      maxWidth="md"
      open={open}
      aria-describedby="alert-dialog-slide-description"
    >
      <div style={webStyle.experienceInnerDiv1}>
        <DialogTitle style={webStyle.dialougeHeading}>New Opportunity</DialogTitle>
        <img
          src={closeIcon}
          style={{
            width: "15px",
            height: "15px",
            marginRight: "32px",
            cursor: "pointer",
          }}
          data-test-id="closeBtnIcon"
          id="close-img"
          onClick={handlePopUpOpportunity}
        ></img>
      </div>
      <DialogContent style={webStyle.dialogContent}>
        <Typography className="subTitle">
        This designer has been sent an invitation to be shortlisted. 
        You will be notified when they accept.
        </Typography>
        <div className="btnContainer">
        <Button className="cancelBtn opportunitySelectionBtn" data-test-id="cancelBtn" onClick={handleJobPostPopUp}>Permanent Position</Button>
        <Button className="cancelBtn opportunitySelectionBtn" data-test-id="cancelBtn">Freelance Role</Button>
        </div>
      </DialogContent>
      <DialogActions style={{ maxHeight: "88px",display:"flex",alignItems:"center" }}>
        <Button className="yesBtn cancelBtn" data-testId="sendDataId">
         Next
        </Button>
      </DialogActions>
    </StyledDialogApplyJob>
  );
};

const webStyle = {
  experienceInnerDiv1: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1195px",
    background: "#000",
    borderRadius: "4px, 4px, 0px, 0px",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    lineHeight: "19.3px",
    color: "white",
    alignItems: "center",
  },
  dialougeHeading: {
    fontFamily: "Silika-Medium",
    fontSize: "16px !important",
    fontWeight: 500,
    lineHeight: "19px",
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap:"wrap" as "wrap"
  },
};
